$white: #fff;
$black: #000;
$themecolor: #d33232;
$primaryColor: #000000;
$secondaryColor: #00000085;
$light: #ffffffc0;
$lightdark: #000000cb;
$lightblack: #00000075;
$darknavBackground: #ffffff90;
$iconColor: #000000b7;
$background1: #f7f7f7;
$gray:#c9c4b3;
// progress card
$labelColor: #333;
$borderColor: #8080803f;
// font family
$fontfamily: "Poppins", sans-serif;
$fontfamily2:'Lora', serif;
// end of color declarations
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}
.wrapper{
  width:100%;
}
.container{
  width: 100%;
  max-width: 1600px;
  padding: 0 15px;
  margin: 0 auto;
}
html {
  font-size: 14px;
}

@media screen and (max-width:767px){
  html {
    font-size: 12px;
  }
}
@media screen and (max-width:575px){
  html {
    font-size: 10px;
  }

}
@media screen and (max-width:375px){
  .img-box-fa8e
    img{
      width: 100%;
      border-radius:  2px ;
      margin:8rem 0rem;
    }
  }


// nav-btn
.quote-btn {
  // max-height: 40px;
  padding: 0.6rem 1.5rem !important;
  font-size: 1rem;
  font-weight: 600;
  font-family: $fontfamily;
  
}
// This css is navigationmobile css
.navigation_mobile:hover {
  background-color:$gray;
  border-radius: 0.8rem;
  .mobile_navicon{
    color:$white;
  }
  .mobile_content{
    color: $white;
  }
}

.drawer-style{
  width:250px;
  max-width: 250px;
  min-width: 250px;
}
// white nav
.white-nav {
  font-family: $fontfamily;
  position: fixed;
  top: 0;
  left: 0;
  transition: position 1s ease;
  animation: forwards firstNav 1.3s;
  z-index: 99;
  .nav-logo {
    height: 3.57rem;
  }
  .nav-content {
    // animation: forwards upNavContent 1.8s;
    padding: 2.85rem 1.07rem 0;
  }
  .link {
    color: $white;
    text-decoration: none;
    padding: 0 0.72rem 0.91rem;
    margin: 0 0.57rem;
    font-size: 0.95rem;
    font-weight: 500;
    position: relative;
  }
  .active-link {
    color: $white;
    text-decoration: none;
    padding: 0 0.72rem 0.91rem;
    font-size: 0.93rem;
    font-weight: 500;
    margin: 0 0.6rem;
    position: relative;
  }
  .link::after {
    position: absolute;
    top: 100%;
    content: "";
    height: 0.16rem;
    width: 0;
    right: 0;
    background-color: $white;
    transition: all ease 0.3s;
  }
  .active-link::after {
    position: absolute;
    top: 100%;
    content: "";
    height: 0.16rem;
    width: 100%;
    right: 0;
    background-color: $white;
    transition: all ease 0.3s;
  }
  .link:hover::after {
    width: 100%;
    left: 0;
  }
}
.dark-nav {
  position: fixed;
  top: 0;
  font-family: $fontfamily;
  background-color: $darknavBackground;
  z-index: 99;
  box-shadow: 0px 1px 7px #00000075;
  transition: position 1s ease;
  animation: forwards switchNav 1.1s;
  .nav-content {
    animation: forwards upNavContent 1.6s;
    padding: 0.71rem 1.07rem 0.71rem;
  }
  .nav-logo {
    height: 3.57rem;
  }
  .link {
    color: $black;
    text-decoration: none;
    padding: 0 0.72rem 0.91rem;
    margin: 0 0.57rem;
    font-size: 0.95rem;
    font-weight: 500;
    position: relative;
  }
  .active-link {
    color: $black;
    text-decoration: none;
    padding: 0 0.72rem 0.91rem;
    font-size: 0.95rem;
    font-weight: 500;
    margin: 0 0.57rem;
    position: relative;
  }
  .link::after {
    position: absolute;
    top: 100%;
    content: "";
    height: 0.16rem;
    width: 0;
    right: 0;
    background-color: $themecolor;
    transition: all ease 0.3s;
  }
  .link:hover::after {
    width: 100%;
    left: 0;
  }
  
  .active-link::after {
    position: absolute;
    top: 100%;
    content: "";
    height: 0.16rem;
    width: 100%;
    right: 0;
    background-color: $themecolor;
    transition: all ease 0.3s;
  }
}
.expand-btn{
  display: none;
}
@media screen and (max-width: 991px) {
  .nav-links {
    display: none;
  }
  .expand-btn{
    display: block;
    position: absolute;
    right: 0;
    margin-right: 1rem;
    font-size: 2rem;
    color: $white;
    margin-top: 0.5rem;
    cursor: pointer;
  }
  .quote-btn{
    margin-right: 2rem;
  }
}
.img-box-fa8e{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .innerOffa8e{
    width: 70%;
    margin:4rem 0rem;
  }
  img{
    width: 100%;
    border-radius:  2px ;
   
  }
}
@keyframes switchNav {
  0% {
    top: -100%;
    opacity: 0;
  }
  100% {
    top: 0%;
    opacity: 1;
  }
}
@keyframes firstNav {
  0% {
    top: 10%;
    opacity: 0;
  }
  100% {
    top: 0%;
    opacity: 1;
  }
}
@keyframes upNavContent {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

// home section styles start
.nested_page #home-section {
  height: 70vh;
  min-height: 550px;

  .home-content {
    min-height: 550px;
    .content-area {
      margin: auto auto 11%;
      label {
        font-size: 17px;
      }
      h5 {
        font-size: 4rem;
        font-weight: 500;
      }
    }
  }
  @media screen and (max-width:767px) {
    height: 100vh !important;

    .home-content {
      .content-area {
        margin: auto auto;
      }
    }
    .img-box-fa8e{
      img{
        width: 600px;
        height: 600px;
        border-radius:  2px ;
        margin:8rem 0rem;
      }
    }
  }
}
@media screen and (min-width:1730px){
  .home-content {
    .content-area {
      margin: auto auto;
    }
  }
}
#home-section {
  position: relative;
  animation: linear unFold 0.5s;
  transition: transform 0.8s;
  // height: 100vh;
  width: 100%;
  min-height: 100vh;
  .home-content {
    position: relative;
    z-index: 3;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    .gradient-layer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      background-image: linear-gradient(
        to right,
        $primaryColor 0%,
        $secondaryColor 100%
      );
      opacity: 0.85;
    }

    .content-area {
      position: relative;
      z-index: 3;
      margin: auto;
      width: 45.2rem;
      
      max-width: 95%;
      animation: content-float infinite 9s;

      label {
        color: $white;
        font-family: $fontfamily;
        white-space: nowrap;
        font-size: 19px;
        line-height: 30px;
      }

      h5 {
        word-wrap: break-word;
        font-size: 3.07rem;
        font-family: $fontfamily;
        color: $white;
        position: relative;
        line-height: 55px;
        margin-bottom: 0.8rem;
        overflow: hidden;

        .layer {
          content: "";
          width: 0;
          height: 100%;
          position: absolute;
          top: 0;
          animation-duration: 2s;
          animation-delay: 3s;
          background-color: $white;
        }
      }

      p {
        white-space: nowrap;
        font-size: 1.3rem;
        line-height: 1rem;
        color: $white;
      }
      button {
        cursor: pointer;
        position: relative;
        margin-top: 2rem;

        text-transform: uppercase;
        border: none;
        overflow: hidden;
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        width: max-content;
        font-size: 0.8rem;
        letter-spacing: 0.08rem;
        .btn-icon {
          color: $themecolor;
          margin-right: 0.2rem;
        }
      }
      button::after {
        content: "";
        position: absolute;
        top: 0;
        display: inline-block;
        width: 0.2rem;
        background-color: $white;
        height: 100%;
        transition: all 7s ease-in;
        animation: shiny-btn1 4s ease-in-out infinite;
      }
      button:hover {
        box-shadow: 1px 2px 7px $white;
      }
      button:hover::after {
        left: 0;
      }
    }

    .prev-arrow-btn,
    .next-arrow-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $white;
      position: relative;
      z-index: 3;
      background: transparent;
      cursor: pointer;
      border: none;
      font-weight: 700;
      font-size: 1rem;
      padding: 0.8rem 1.45rem;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
      span {
        width: 3px;
        background-color: $white;
        height: 20px;
        margin-bottom: 5px;
      }
    }
    @media screen and (max-width: 991px) {
      .prev-arrow-btn,
      .next-arrow-btn {
        display: none;
        background: red;
      }
      
      justify-content: center;
    }
    .prev-arrow-btn {
      transform: rotate(-90deg);
      margin-left: 1.5rem;
    }
    .next-arrow-btn {
      transform: rotate(90deg);
      margin-right: 1.5rem;
    }
  }
}

.awssld {
  height: 100vh;
}
.awssld__bullets {
  display: none !important;
}
.awssld__next,
.awssld__prev {
  opacity: 0;
}

@keyframes unFold {
  0% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0);
  }
}
.home-section-wrap {
  width: 100%;
  background-color: transparent;
  box-sizing:border-box;
}

@keyframes content-float {
  0% {
    transform: translate(0px, -20px);
  }

  40% {
    transform: translate(0px, -10px);
  }

  80% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0px, -20px);
  }
}
@-webkit-keyframes content-float {
  0% {
    transform: translate(0px, -20px);
  }

  40% {
    transform: translate(10px, 0px);
  }

  80% {
    transform: translate(0, 0px);
  }
  100% {
    transform: translate(0px, -20px);
  }
}

// shiny btn
@keyframes shiny-btn1 {
  0% {
    transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}
@-webkit-keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}

// progress section

#progress {
  width: 100%;
  overflow: hidden;
  background: $white;
  padding-top: 10rem;
  padding-bottom: 10rem;
  font-family: $fontfamily;
  // @media only screen and (min-width:1720px){
  //   padding-top: 20rem;
  // }
  .progress-card-wrap {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem 2rem;
    .progress-card {
      width: auto;
      height: 200px;
      border: 4px solid $borderColor;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      span {
        position: absolute;
        content: "";
        height: 5rem;
        width: 50%;
        top: -3rem;
        left: 50%;
        background: $white;
        transform: translate(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .title {
        font-size: 1.2rem;
        margin-bottom: 0.8rem;
        color: $iconColor;
      }
      .progress-icon {
        color: $themecolor;
        font-size: 3rem;
      }
      .content {
        max-width: 70%;
      }
    }
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      
    }
  }

  .breif {
    font-family: $fontfamily;
    // padding-top: 10rem;
    display: grid;
    grid-template-columns: 42% 58%;
    gap: 1.5rem;
    img {
      height: 350px;
      width: 100%;
    }
    .content {
      display: flex;
      flex-direction: column;
      h3 {
        color: $black;
        font-size: 2.6rem;
        span {
          color: $themecolor;
        }
      }
      p {
        color: #333333;
        font-size: 1.2rem;
        letter-spacing: 0.015rem;
        text-align: left;
        padding-top: 1.5rem;
        font-weight: 400;
      }
      .description {
        color: #333333;
        font-size: 0.95rem;
        text-align: left;
        padding-top: 1.5rem;
        font-weight: 400;
        padding-bottom: 1.5rem;
      }
      button {
        cursor: pointer;
        text-transform: uppercase;
        position: relative;
        overflow: hidden;
        border: none;
        overflow: hidden;
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        width: max-content;
        font-size: 0.8rem;
        letter-spacing: 0.08rem;
        .btn-icon {
          margin-right: 0.2rem;
          color: $themecolor;
        }
      }
      button:hover {
        box-shadow: 1px 2px 5px $borderColor;
      }
      button::after {
        content: "";
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 0.2rem;
        background-color: $white;
        height: 100%;
        transition: all 7s ease-in;
        animation: shiny-btn1 4s ease-in-out infinite;
      }
      button:hover::after {
        left: 0;
      }
    }
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(1, 1fr);
    
    }
  }

}

// countdown section

#countdown {
  background: $white;
  padding: 4rem 0 6rem;
  font-family: $fontfamily;
  .achievments-title {
    color: $themecolor;
    font-size: 3rem;
    font-weight: 500;
    padding-bottom: 3rem;
  }
  .countdown-wrap {
    display: grid;
    width: 975px;
    max-width: 100%;
    margin: 0 auto;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.2rem;
    .counter-box {
      border-top: 1px solid $borderColor;
      border-bottom: 1px solid $borderColor;
      padding: 2rem 1rem;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      .icon {
        font-size: 3rem;
        color: $themecolor;
      }
      .count-box {
        // background-color: red;
        margin-top: 0;
        padding-left: 1rem;
        span {
          font-size: 3rem;
          line-height: 1;
          padding-bottom: 0.8rem;
          color: #333333;
          font-weight: 600;
        }
        .plus {
          color: #333333;
          font-size: 2rem;
          line-height: 1;
          // padding-bottom: 0.5rem;
        }
        p {
          margin-top: 0.5rem;
          color: #000000a4;
          font-size: 0.95rem;
          max-width: 80%;
        }
      }
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .img-box-fa8e{
      img{
        width: 5  00px;
        height: 600px;
        border-radius:  2px ;
        margin:8rem 0rem;
      }
    }
  }
}

#portfolio-cards {
  width: 100%;
  padding-bottom: 6rem;
  background: $background1;
  font-family: $fontfamily;
  .mansonry-item {
    position: relative;
    overflow: hidden;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
    .overlay {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 0%;
      content: "";
      z-index: 4;
      opacity: 0;
      cursor: pointer;
      transition: all ease-in-out 0.4s;
      background-image: linear-gradient(
        to bottom left,
        $themecolor 13%,
        #a31c1cc2 100%
      );
      .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        .links {
          display: flex;
          align-items: center;
          flex-direction: row;
          margin-bottom: 1.5rem;
          span {
            height: 3.5rem;
            min-width: 3.5rem;
            width: 3.5rem;
            background-color: $lightdark;
            border-radius: 50%;
            display: grid;
            justify-content: center;
            align-items: center;
            color: $light;
            transition: all ease-in-out 0.3s;
            font-size: 1.4rem;
          }
          .chain {
            margin-right: 0.8rem;
          }
          span:hover {
            background-color: $black;
            color: $white;
          }
        }
        h5 {
          color: $white;
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }
        p {
          color: $white;
          width: 80%;
          text-align: center;
          transform: translateY(2rem);
          font-size: 0.95rem;
          transition-delay: 2s;
          transition: all ease-in-out 1.3s;
        }
      }
    }
    .overlay:hover p {
      transform: translateY(0);
    }
  }
  .mansonry-item:hover .overlay {
    bottom: 0;
    opacity: 1;
    height: 100%;
  }
}
.shine-btn {
  border: none;
  padding: 1.2rem 2.5rem;
  text-transform: uppercase;
  color: $white;
  background: $themecolor;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  box-shadow: 0 8px 32px rgba(#000, 0.2);
  transition: all 0.2s ease;
}
.shine-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 30%;
  height: 100%;
  transform: skewX(-20deg);
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(#fff, 0.5),
    transparent
  );
}

.shine-btn:hover:after {
  animation: shine 1.6s ease;
}
@keyframes shine {
  100% {
    left: 200%;
  }
}

.services-box {
  font-family: $fontfamily;
  width: 100%;
  background: transparent;
  display: flex;
  padding: 0 0 4rem;
  flex-direction: column;
  .works {
    color: $themecolor;
    font-size: 1rem;
    font-weight: 500;
  }
  .title {
    font-size: 2.5rem;
    color: $lightdark;
    padding-bottom: 1rem;
  }
  .link-wrap {
    width: 100%;
    padding-top: 1rem;
  }
  .active-card-link,
  .link {
    color: $lightblack;
    text-decoration: none;
    margin-bottom: 0.1rem;
    font-size: 1.1rem;
    font-weight: 400;
    text-transform: uppercase;
    position: relative;
    width: max-content;
  }
  .active-card-link {
    color: $black;
  }

  .link::after {
    position: absolute;
    top: 100%;
    content: "";
    height: 0.14rem;
    width: 0;
    right: 0;
    background-color: $black;
    transition: all ease 0.3s;
  }
  .link-wrap:hover {
    cursor: pointer;
  }
  .link-wrap:hover .link {
    color: $black;
  }

  .active-card-link::after {
    position: absolute;
    top: 100%;
    content: "";
    height: 0.14rem;
    width: 100%;
    right: 0;
    background-color: $black;
    transition: all ease 0.3s;
  }

  .link-wrap:hover .link::after {
    width: 100%;
    left: 0;
  }
  .link:hover::after {
    width: 100%;
    left: 0;
  }
}

#team {
  padding-top: 5rem;
  width: 100%;
  .team-inner-wrap {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .team-slider-wrap {
    width: 35rem;
    max-width: 100%;
    margin: 0 auto;

    .item {
      position: relative;
      text-align: center;
      display: flex !important;
      align-items: start;
      height: 13rem;
      justify-content: center;
      img {
        height: 10rem;
        width: 10rem;
        border-radius: 50%;
      }
      span {
        height: 0;
        width: 0;
        border-radius: 50%;
        background-color: $themecolor;
        position: absolute;
        transition: all ease-in-out 0.4s;
        bottom: 1rem;
        z-index: 4;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          color: transparent;
        }
      }
    }
  }
  .slick-active.slick-center .item {
    transform: scale(1);
    transition: all ease 0.4s;
    opacity: 1;
    span {
      height: 4rem;
      width: 4rem;
      padding: 1rem;
      border: 0.3rem solid $background1;
      .icon {
        color: $white;
        font-size: 2rem;
      }
    }
  }
  .slick-active.slick-center:hover .item {
    opacity: 1;
    cursor: pointer;
    transform: scale(1);
  }
  .slick-slide .item,
  .slick-slide.slick-active.item,
  .slick-cloned .item {
    transform: scale(0.7);
    transition: all ease 0.4s;
    opacity: 0.7;
  }
  .slick-slide:hover .item {
    opacity: 1;
    cursor: pointer;
    transform: scale(0.8);
  }
  .slick-prev {
    transform: translate(-50px);
  }
  .slick-next {
    transform: translate(50px);
  }

  .slick-nav {
    height: 3rem;
    width: 3rem;
    border: 1px solid $lightblack;
    border-radius: 50%;
    background-color: transparent;
    transition: all ease-in-out 0.3s;
  }
  .slick-nav:hover {
    background-color: $black;
    color: $white;
  }
  .slick-prev::before,
  .slick-next::before {
    content: "";
  }
  .slick-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $black;
    cursor: pointer;
  }
  .slick-left {
    left: -8rem;
  }
  .slick-right {
    right: -8rem;
  }
  // -----
  .quotes-wrap {
    font-family: $fontfamily;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    h3 {
      color: $lightdark;
      font-weight: 400;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    label {
      color: $lightblack;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.3rem;
      font-family: "Roboto", "sans-serif";
    }
    .slick-arrow {
      display: none !important;
    }
  }
  .creative-team {
    text-align: center;
    .title {
      color: #333;
      font-family: $fontfamily;
      font-size: 2.8rem;
      font-weight: 500;
      span {
        color: $themecolor;
      }
    }
    .team-brief {
      width: 600px;
      max-width: 100%;
      margin: 0 auto;
      font-size: 1.2rem;
      color: #666;
      line-height: 2rem;
    }
  }
  @media screen and (max-width: 750px) {
    .slick-left {
      left: -1rem;
    }
    .slick-right {
      right: -1rem;
    }
    .quotes-wrap {
      width: 100%;
    }
  }

  .brand-container {
    padding-bottom: 5rem;

    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
    .brand-wrap {
      border: 1px solid $borderColor;
      height: 120px;
      width: 210px;
      opacity: 0.7;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all ease-in 0.3s;
      cursor: pointer;
      margin: 10px auto;
    }
    .brand-wrap:hover {
      opacity: 1;
    }
  }
}
// --------------------Footer css starts here

#footer {
  width: 100%;
  background: $black;
  .footer-skype-icon {
    position: fixed;
    right: 1.7rem;
    bottom: 5rem;
    border-radius: 50%;
    height: 3.6rem;
    width: 3.6rem;
    background-color: #00aff0;
    z-index: 98;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      box-shadow: 0 0 5px rgb(0 0 0 / 20%);
    }
    a {
      text-decoration: none;
      color: $white;
      font-size: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  footer {
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 2rem 0px;
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
    .left {
      display: flex;
      flex-direction: row;
      align-items: start;
      width: 45%;
      justify-content: space-between;
      @media screen and (max-width: 991px) {
        flex-direction: column;
        width: 100%;
        .whatsapp {
          padding-top: 2rem;
        }
      }
      .footer-logo {
        width: 150px;
      }
      .whatsapp {
       

        .cloneWhatsapp{
         margin-top: 6rem;
        }
        p
        {
         
          text-align: center;
          color: white  ;
        }
      }
    }
    .right {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      width: 25%;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
      .copyright {
        color: $light;
        font-size: 0.82rem;
        font-weight: 400 !important;
      }
      .icon-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        .icon {
          color: $white;
          font-size: 2rem;
          margin-right: 1rem;
          transition: all ease-in 0.2s;
          cursor: pointer;
          &:hover {
            transform: scale(1.1);
            color: $themecolor;
          }
        }
      }
    }
  }
}

.loader-box {
  position: absolute;
  top: 50%;
  left: 10%;
  right: 10%;
  z-index: 1;
  width: 0;
  border-radius: 1rem;
  background: linear-gradient(to right, $white 0%, $themecolor 9%);

  height: 1rem;
  animation: load 1s ease-in-out forwards;
  label {
    position: absolute;
    top: -2rem;
    left: 0;
    font-weight: 500;
    color: $lightblack;
    font-size: 1.5rem;
  }
}
@keyframes load {
  0% {
    width: 0%;
  }
  100% {
    width: 80%;
  }
}

#contact {
  width: 100%;
  background: #f7f7f7;
  font-family: $fontfamily;
  .contact-box {
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .title {
      color: $themecolor;
      font-size: 0.93rem;
      font-weight: 500;
    }
    h3 {
      font-size: 2.8rem;
      font-weight: 500;
    }
    .consult-line {
      font-size: 0.96rem;
      color: #666;
      padding-top: 1.3rem;
      padding-bottom: 3rem;
    }
    .text-input {
      border: 1px solid $borderColor;
      height: 2rem;
      width: 280px;
      -moz-appearance: textfield;
      border: 1px solid #ebeced;
      background-color: #fff;
      height: 50px;
      font-size: 14px;
      line-height: 1.7em;
      padding: 7px 18px 3px 18px;

      border-radius: 5px 5px 5px 5px;
      color: #3b3b3b;
      box-shadow: 10px 0px 40px 0px rgb(0 4 60 / 8%);
    }
    .input-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
    .input-wrap {
      display: flex;
      flex-direction: column;
      text-align: left;
      color: $themecolor;
      font-size: 0.8rem;
    }
    textarea {
      width: 100%;
      border: 1px solid #ebeced;
      background-color: #fff;
      min-height: 150px;
      max-height: 150px;
      font-size: 14px;
      max-width: 100%;
      min-width: 100%;
      line-height: 1.7em;
      padding: 7px 18px 3px 18px;
      border-radius: 5px 5px 5px 5px;
      color: #3b3b3b;
      box-shadow: 10px 0px 40px 0px rgb(0 4 60 / 8%);
    }
    .submiit-btn {
      margin-top: 2rem;
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 0.1rem;
    }
    @media screen and (max-width: 767px) {
      .input-row {
        flex-direction: column;
        margin-bottom: 0;
      }
      .input-wrap {
        width: 100%;
        margin-bottom: 1rem;
      }
      .text-input {
        width: 100%;
      }
      textarea {
        margin-top: 1rem;
      }
    }
  }
}

// =============awesome slider scss
.awssld__container {
  height: 100vh !important;
  // min-height: 500px;
}

//-----------  services ----------------
#services_grid_wrap {
  width: 100%;
  padding: 5rem 0;
  ._grid {
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    .__item {
      width: 100%;
      display: flex;
      padding-bottom: 2rem;
      padding-top: 2rem;
      flex-direction: row;
      align-items: flex-start;
      border-bottom: 1px solid #6666662f;
      height: auto;
      .icon-wrap {
        width: 4rem;
        .icon {
          font-size: 3.5rem;
          color: $themecolor;
        }
      }

      .item-content-box {
        padding-left: 2rem;
        display: flex;
        flex-direction: column;
        .title {
          font-family: $fontfamily;
          font-weight: 600;
          text-transform: none;
          color: #222222d5;
          font-size: 1.1rem;
        }
        p {
          color: #666666;
          line-height: 1.75rem;
          font-size: 0.98rem;
          font-family: $fontfamily;
        }
      }
    }
    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
.home_about_btn_main {
  margin-top: 22px;
  margin-bottom: 12px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 13px 28px;
  line-height: 1;
  display: inline-block; /*imp*/
  .home_about_btn_sub {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 0.7rem;
    letter-spacing: 0.015em;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    #caret-down-about {
      color: $white;
      transform: rotate(180deg);
      font-size: 1rem;

      margin-right: 15px;

      margin-left: 9px;
    }
    .nested_link,
    p {
      text-decoration: none;
      color: $white;
      font-size: 0.8rem !important;
    }
    .nested_link:hover {
      color: yellow;
    }
  }
}

/* our-services */

.our-working-plan-main {
  font-family: $fontfamily;
  background-color: #141714;
  padding-top: 110px;
  padding-bottom: 110px;
}
.ul-main-services {
  display: flex;
  flex-direction: column;
  font-family: $fontfamily;
  align-items: flex-start;
  li {
    width: 100%;
  }
}

.our-working-plan-text {
  color: #ffffff;
  margin-bottom: 7px;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  font-weight: 500;
  line-height: 1.625;
}

.our-working-philosophy-text {
  line-height: 1.1;
  font-size: 38px;
  font-weight: 600;
  color: $themecolor;
  margin-bottom: 1.25rem;
  text-align: center;
}

.three-li-services {
  padding-top: 2.5rem;
  width: 100%;
}

@media screen and (min-width: 992px) {
  .ul-main-services {
    flex-direction: row;
  }
  .ul-main-services .the-research-planing::after {
    position: absolute;
    content: "";
    top: 14px;
    left: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    width: 100%;
    height: 1px;
    display: block;
  }
}

.ul-main-services .the-research-planing:last-child:after {
  position: absolute;
  content: "";
  width: 0%;
  height: 0px;
  display: block;
}

.the-research-planing {
  position: relative;
  display: inline-block;
  width: 33.33%;
  margin-right: -3px;
  padding-right: 71px;
  padding-left: 71px;
  padding-top: 0;
  text-align: center;
}

.zero-one-text {
  margin-top: 23px;
  margin-bottom: 29px;
  font-size: 60px;
  color: #fff;
  line-height: 1;
  opacity: 0.7;
  transition: opacity 0.3s ease-in;
}

.client-centricity-text {
  margin-bottom: 10px;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.015em;
}

.clients-para {
  color: #ccc;
  line-height: 2;
  text-align: center;
  font-size: 13px;
  letter-spacing: 0.015em;
}

.red-circle {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 29px;
  height: 29px;
  /* background-color: #000; */
}

.the-research-planing .red-circle::before {
  background: $themecolor;

  position: absolute;
  top: 10px;
  left: 10px;
  display: block;
  width: 9px;
  height: 9px;
  content: "";
  border-radius: 50%;
  box-shadow: 0 0 0 5px rgb(255 255 255 / 12%);
}

.the-research-planing .red-circle::after {
  background: $themecolor;

  display: block;
  width: 1px;
  height: 15px;
  margin: 19px auto 0;
  content: "";
}

#tech_list_box {
  font-family: $fontfamily;
  border-bottom: 1px solid $borderColor;
  .tech_label {
    font-size: 3rem;
    text-transform: uppercase;
    color: $labelColor;
    font-weight: 600;
    padding-top: 6rem;
    padding-bottom: 4rem;
  }
  .tech_grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2rem;
    .grid_item {
      width: 100%;

      text-align: center;
      .box {
        box-shadow: 0 0 5px rgb(0 0 0 / 20%);
        text-align: center;
        img {
          height: auto;
          max-width: 100%;
        }
      }
      .bottom-text {
        color: #666;
        font-size: 1rem;
        text-transform: none;
        line-height: 1.9rem;
        font-weight: 600;
        padding-top: 1.7rem;
        margin-bottom: 3rem;
      }
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      .grid_item {
        padding: 0 10%;
      }
    }
    @media screen and (max-width: 575px) {
      .grid_item {
        padding: 0;
      }
    }
  }
}

// life @ sunfocus section and slider

#life_sunfocus {
  font-family: $fontfamily;

  .about-sunfocus {
    padding-top: 5rem;
    width: 840px;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 3rem;
    h2 {
      font-size: 2.8rem;
      color: $labelColor;
      font-weight: 500;
      text-transform: none;
      margin-bottom: 2rem;
      font-family: $fontfamily;
    }
    p {
      color: $labelColor;
      margin-bottom: 1.25rem;
      line-height: 1.7rem;
      padding-bottom: 2rem;
      font-family: $fontfamily;
    }
    button {
      font-size: 0.8rem;
      font-weight: 600;
      letter-spacing: 0.06rem;
      font-family: $fontfamily;
      display: flex;
      align-items: center;
      margin: 0 auto;
      .btn-icon {
        font-size: 1rem;
        transform: translateY(-0.06rem);
      }
    }
  }
  .full-screen-slider {
    padding-top: 4rem;
    padding-bottom: 4rem;
    overflow: hidden;
    width: 100%;
    .img-wrap {
      width: auto;
      height: auto;
      img {
        height: auto;

        width: 100%;
        cursor: all-scroll;
      }
    }
  }
}

// why sunfocus section

.why-sunfocus {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 7rem;
  align-items: flex-start;
  border-bottom: 1px solid $borderColor;
  padding-bottom: 3rem;
  font-family: $fontfamily;
  .left-box {
    width: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: 0.4s ease-in-out;
      transition: 0.4s ease-in-out;
    }
    &:hover img {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }
  }
  .right-box {
    width: 50%;
    padding-left: 6rem;
    h2 {
      color: $labelColor;
      font-size: 3rem;
      font-weight: 500;
    }
    ul {
      padding-left: 1.7rem;
      padding-top: 2rem;
      li {
        font-size: 1rem;
        line-height: 1.7rem;
        color: $labelColor;
        padding-bottom: 1rem;
      }
    }
  }
  @media screen and (max-width: 991px) {
    flex-direction: column;
    .left-box,
    .right-box {
      width: 100%;
      padding-left: 0;
    }
  }
}

#contacts_map {
  font-family: $fontfamily;
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div {
    width: 50%;
    iframe {
      min-height: 600px;
    }
  }
  .right_box {
    display: flex;

    flex-direction: column;
    justify-content: flex-start;
    width: 600px;
    max-width: 100%;
    padding-left: 4rem;
    padding-top: 4rem;
    .label {
      font-size: 1.5rem;
      color: #232323;
      line-height: 1.1;
      font-weight: 500;
      padding-bottom: 3rem;
    }
    p {
      font-size: 1rem;
      text-decoration: none;
      margin-bottom: 1.3rem;
      color: inherit;
      display: flex;
      flex-direction: row;
      align-items: center;
      a {
        text-decoration: none !important;
        color: inherit;
      }
      .icon {
        margin-right: 1rem;
        color: $themecolor;
        font-size: 1.4rem;
      }
    }
    h2 {
      padding-top: 3rem;
      padding-bottom: 1rem !important;
    }
    form {
      width: 500px;
      max-width: 100%;
      .contact-input {
        border: none;
        margin-top: 2rem;
        border-bottom: 1px solid #ddd;
        letter-spacing: 0.02rem;
        height: 3rem;
        width: 100%;
        color: #908c8a;
        padding: 12px 4px 6px;
        &::placeholder {
          color: #908c8a;
          font-family: $fontfamily;
        }
      }
      textarea {
        height: 120px;
        width: 100%;

        border: none;
        margin-top: 2rem;
        border-bottom: 1px solid #ddd;
        letter-spacing: 0.02rem;
        resize: none;
        width: 100%;
        color: #908c8a;
        padding: 12px 4px 6px;
        &::placeholder {
          color: #908c8a;
          font-family: $fontfamily;
        }
      }
      button {
        margin: 3rem auto;
        letter-spacing: 1px;
        height: 35px;
        text-align: center;
        font-weight: 500;
        width: max-content;
        padding: 0.5rem 2rem;
      }
    }
  }
  @media screen and (max-width: 891px) {
    flex-direction: column;
    & > div {
      width: 100%;
    }
    .right_box {
      padding-left: 15px !important;
      padding-right: 15px !important;
      width: 100%;
      padding-left: 0;
    }
  }
}

// works section
#works-card-wrap {
  padding-top: 8rem;
  background: $background1;
  .shine-btn {
    display: none;
  }
}

// know more about company section
#know-more {
  width: 100%;
  text-align: center;
  padding-top: 8rem;
  padding-bottom: 3rem;
  font-family: $fontfamily;
  border-bottom: 1px solid $borderColor;
  margin-bottom: 4rem;
  h1 {
    font-size: 2.7rem;
    margin-bottom: 1.5rem;
    font-weight: 500;
    color: $labelColor;
  }
  p {
    color: #161616;
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 2rem;
  }
}

#experience-year {
  width: 100%;
  position: relative;
  height: max-content;
  font-family: $fontfamily;
  padding-bottom: 5rem;
  .polygon-shape {
    width: 100%;
    height: 100%;
    background-color: $background1;
    clip-path: polygon(0 0, 100% 0, 100% 30%, 0 80%, 0 0);
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
  }
  .content {
    position: relative;
    z-index: 2;
    .flex-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .left,
      .right {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        p {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: $labelColor;
          font-size: 1.3rem;
          h1 {
            color: $themecolor;
            font-size: 5rem;
            font-weight: 500;
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
        h3 {
          color: $labelColor;
          font-size: 1.4rem;
          font-weight: 600;
        }
      }
      .right {
        justify-content: flex-end;
        overflow: hidden;
        img {
          width: 100%;
          height: auto;
          margin-left: auto;
          margin-top: 3rem;
          transition: all ease-in 0.3s;
        }
        &:hover img {
          transform: scale(1.2);
        }
      }
      @media screen and (max-width: 991px) {
        flex-direction: column;
        .left,
        .right {
          width: 100%;
        }
      }
    }
  }
}

#client-thought{
  text-align: center;
  padding: 10rem 0 4rem;
  border-bottom: 1px solid $borderColor;
  font-family: $fontfamily;
  margin-bottom: 3rem;
  h5{
    color: rgba(34, 34, 34, 0.856);
    font-size: 1.3rem;
    font-weight: 500;
  }
  h1{
    color: $themecolor;
    font-weight: 600;
    font-size: 3.1rem;
  }
  p{
    padding-top: 2rem;
    margin-bottom: 0.4rem;
    color: #666;
    font-size: 0.92rem;
    line-height: 2rem;
  }
 
}
.cilent-block{
  width: 950px;
  margin: 0 auto;
  max-width: 100%;
  display: flex;
 flex-direction: row;
 font-family: $fontfamily;
 justify-content: center;
 border-bottom:  1px solid $borderColor;
 margin-bottom: 3rem;
 padding-bottom: 1rem;
 align-items: start;
 &:last-child{
 border-bottom: none;
 align-items: center;
 }
 &:nth-child(3),&:nth-child(4){
   align-items:center ;
 }

  .img-box{
    width:40%;
    text-align: center;
    
    img{
      height: 300px;
      margin:  auto;
      border-radius: 6px;
    
  
    }

  }
  
  .content-box{
    width:60%;
    p{
      line-height: 1.5;
    font-size: 1.2rem;
    color: #222222c5;
  font-style: italic;  
  padding-bottom: 2rem;
  font-family: $fontfamily2;
  font-weight: 500;
  }
  h2{
    font-size: 1.5rem;
    font-weight: 700;
    display: block;
    line-height:2.4rem;
    font-family: $fontfamily;
    color: #333333;
  }
  .position{
    color: $themecolor;
    font-family: $fontfamily;
    font-style: normal;
    font-size: 1.05rem;
    font-weight: 600;
  }
  }
  @media screen and (max-width:991px) {
    .content-box{
      padding-left: 2rem;
    }
  }
  @media screen and (max-width:767px) {
    flex-direction: column;
 
    .img-box,.content-box{
      width: 100%;
    }
    .content-box{
      padding-left: 0;
      order: 9;
    }
  }
}
